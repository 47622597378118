jQuery(function ($) {

  $('.def6-block.two-tile').each(function () {

    let desktopDestination = '.arrow-desktop';
    let mobileDestination = '.arrow-mobile';

    if( $(this).hasClass('extend-arrow') ) {

      desktopDestination = '.arrow-desktop-extended';
      mobileDestination = '.arrow-mobile-extended';
    }

    // animation script
    gsap.to(desktopDestination, { 
      scrollTrigger: {
        trigger: ".two-tile--content-wrap--cards",
        start: 'top bottom',
        scrub: true,
      },
      bottom: '0'
    });
    
    gsap.to(mobileDestination, { 
      scrollTrigger: {
        trigger: ".two-tile--content-wrap--cards",
        start: 'top bottom',
        scrub: true,
      },
      bottom: '0'
    });

  });
});